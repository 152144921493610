<template>
	<div class="Invoice">
		
		<div class="Top">
			<div class="Left">
				<el-button type="primary" icon="el-icon-plus" @click="$Jump('/my/invoice/info/add')">新增发票</el-button>
			</div>
			<div class="Right">
				<span>
					当有多条开票信息时，只能同时启用一条
				</span>
			</div>
		</div>
		
		<div class="List">
			
			<div class="One" v-if="InvoiceList.length == 0">
				<li>
					<span>提示</span>
					<em>当前没有发票信息，<i @click="$Jump('/my/invoice/info/add')">点此创建发票</i></em>
				</li>
			</div>
			
			<div class="One" v-for="item in InvoiceList" :key="item.Id">
				<li>
					<span>发票抬头</span>
					<em>
						{{item.Title}}
						<em style="color: #999999;margin-left: 10px;">
						<template v-if="item.Type == 'person'">个人增值税普票</template>
						<template v-else-if="item.Type == 'normal'">单位增值税普票</template>
						<template v-else-if="item.Type == 'special'">单位增值税专票</template>
						</em>
						<label>创建于{{item.CreatedAt}}</label>
						
					</em>
				</li>
				<li v-if="item.Type != 'person'">
					<span>发票税号</span>
					<em>{{item.DutyParagraph}}</em>
				</li>
				<li>
					<span>当前状态</span>
					<em>
						{{item.StatusName}} 
						<template v-if="item.Status == 50">
						- {{item.IsDefaultName}}
						</template>
					</em>
					<i>
						<el-button size="mini" @click="$Jump('/my/invoice/info/'+item.Id)" type="primary">编辑</el-button>
						<el-button v-if="item.Status > 10" size="mini" @click="UpdateInvoice(item.Id,10)" type="danger">停用</el-button>
						<el-button v-if="item.Status == 10" size="mini" @click="UpdateInvoice(item.Id,50)" type="success">启用</el-button>
						<el-button v-if="item.IsDefault == 1" size="mini" @click="UpdateInvoice(item.Id,2)" type="danger">关闭默认</el-button>
						<el-button v-if="item.IsDefault == 2" size="mini" @click="UpdateInvoice(item.Id,1)" type="success">启用默认</el-button>
						<el-button size="mini" @click="Del(item.Id,-1)" type="info">删除</el-button>
					</i>
				</li>	
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'InvoiceList',
	  props: {
	  },
	  data() {
	      return {
			  InvoiceList:[],
			  Page:1,
			  PageSize:8,
			  Total:0
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  created() {
	  	this.GetList(1)
	  },
	  methods:{
		  handleCurrentChange(val){
		  			 this.GetList(val)
		  },
		  Del(_id){
			  this.$confirm('此操作将删除, 是否继续?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			    }).then(() => {
					this.UpdateInvoice(_id,-1)
			    })
		  },
		  GetList(_page){

		  		let data = {
		  			Service:'Goods',
		  			Class: 'Invoice',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.InvoiceList = res.Data.InvoiceList
					this.Page = res.Data.CurrentPage
					this.Total = res.Data.Total
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  UpdateInvoice(_id,_status){
		  	
		  	let data = {
		  		Service:'Goods',
		  		Class: 'Invoice',
		  		Action: 'Update',
		  		Id:_id,
		  		Status:_status,
		  	}
			if(_status == -1){
				data.Action = 'Del'
			}else if(_status == 1){
				data.IsDefault = 1
				delete data.Status
			}else if(_status == 2){
				data.IsDefault = 2
				delete data.Status
			}
		  	
		  	this.$post(this.$store.getters.getApiHost,data)
		  	.then((res) => {
		  		
		  		if(res.ErrorId != 0){
		  			this.$message(res.Msg)
		  			return
		  		}
		  		if(_status == 50){
					this.$message('启用成功')
				}else if(_status == 10){
					this.$message('停用成功')
				}else if(_status == 2){
					this.$message('停用默认成功')
				}else if(_status == 1){
					this.$message('启用默认成功')
				}else if(_status == -1){
					this.$message('删除成功')
				}
		  		this.GetList()
		  		
		  	})
		  	
		  }
	  }
	}
</script>

<style scoped>
.Invoice{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 20px;
}
.Invoice .Top{
	display: flex;
	align-items: center;
}
.Invoice .Top .Left{
	
}
.Invoice .Top .Right{
	flex: 1;
	text-align: right;
	color: #999999;
}

.Invoice .List{
	margin-top: 20px;	
}
.Invoice .List .One{
	border: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin-bottom: 20px;
}
.Invoice .List .One:hover{
	background-color: rgba(228,0,0,0.1);
}
.Invoice .List .One li{
	display: flex;
	line-height: 20px;
	padding: 10px 0px;
	align-items: center;
}
.Invoice .List .One li span{
	width: 100px;
	color: rgba(0,0,0,0.3);
}
.Invoice .List .One li  em{
	flex: 1;
}
.Invoice .List .One li  em i{
	color: rgba(228,0,0,1);
	cursor: pointer;
	font-style: normal;
}
.Invoice .List .One li  em label{
	font-size: 12px;
	color: #999999;
	float: right;
}
.Invoice .List .One li i{
	
}
</style>
